.translations-wrapper {
    display: flex;
}

.translations-content-wrapper {
    width: 100%;
    padding: 5px 30px 0px 20px;
    justify-content: center;
    min-height: 300px;
    display: flex;
}

.translations-content-wrapper.texts-loaded {
    display: block;
}


#texts-panel>.Polaris-Card__Section {
    padding-left: 0;
    padding-top: 2px;
}

.local-select {
    display: flex;
    align-items: center;
}

.local-select div {
    width: 300px;
}

@media (max-width: 720px) {
    .local-select div {
        width: 50%;
    }
}

.local-select .Polaris-Label__Text {
    font-weight: bold;
}

.texts-restore {
    display: block;
    margin-top: 20px;
}

.spinner-texts {
    display: flex;
    align-self: center;
}

.locales-mgmt {
    display: flex;
    margin-top: 10px;
}

.sync-products-button {
    margin-left: 5px;
}

.add-locale-button {
    margin-left: auto;
}

.remove-locale-button {
    margin-left: 5px;
}

.sync-products-progress-bar {
    margin-bottom: 10px;
}

.sticky-atc-texts {
    padding: 15px 25px;
}

.sticky-atc-texts .Polaris-Card {
    padding: 5px 20px 20px 20px;
    margin-top: 10px;
}


.sticky-atc-texts .save-button {
    position: fixed;
    bottom: 15px;
}

.sticky-atc-texts .save-button>button {
    width: 140px;
    height: 50px;
}